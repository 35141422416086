<template>
    <div>
        <div class="card card-custom mt-5">
            <div class="card-body">
                <!-- Filter -->
                <div>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">
                            <div class="col-12 mt-10">
                                <table class="table table-bordered">

                                    <tbody>
                                    <tr v-if="$can('report_items.report_menu_items')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.items_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'items-report.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="$can('report_items.report_items_transactions')">
                                        <td class="d-flex justify-content-between">
                                            <span>{{$t('MENU.items_transactions_report')}}</span>
                                            <div>
                                                <router-link :to="{name: 'items-transactions-report.list'}" target="_blank">
                                                    {{$t('view')}}
                                                </router-link>
                                            </div>
                                        </td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

    export default {
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.items_report")}]);
        },
    }
</script>
<style scoped>
    td {
        padding: 1rem;
    }
</style>